import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {FirebaseService} from '../firebase/firebase.service';
import {IUser} from '../../core/interfaces/user';
import {take} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UserStatusService {

  currentUserSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  currentUser: BehaviorSubject<any> = new BehaviorSubject<any>({});
  currentUserSubscription: Subscription;

  constructor(
    private firebase: FirebaseService
  ) {
    this.currentUserSubject.subscribe((user) => {
      this.currentUser.next(user);
    });
  }



  setUserOnline() {
    const status = {
      status: 'online',
      lastSeen: ''
    }
    this.currentUserSubscription = this.currentUser.subscribe((user: IUser) => {
      this.firebase.setUserStatus(user.uid, status);
      if (this.currentUserSubscription) {
        this.currentUserSubscription.unsubscribe();
      }
    });
  }

  setUserOffline() {
    const status = {
      status: 'away',
      lastSeen: Date.now()
    }
    return new Observable((observer) => {
      this.currentUserSubscription = this.currentUser.pipe(take(1)).subscribe((user: IUser) => {
        this.firebase.setUserStatus(user.uid, status).then(() => {
          observer.next(user);
          if (this.currentUserSubscription) {
            this.currentUserSubscription.unsubscribe();
          }
        });
      });
    });
  }
}
